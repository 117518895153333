import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Container, Label, Nav, Modal, ModalBody, ModalFooter, NavItem, NavLink, Row, TabContent, TabPane, Spinner, ModalHeader } from "reactstrap";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import good from "../../assets/images/good.svg";
import bad from "../../assets/images/bad.svg";
import "react-datepicker/dist/react-datepicker.css";
import { FeedbackGetAPI } from "service/notifactionService";
import moment from "moment";

const FeedbackDetail = (props) => {

  const { startDate, startDateFrom, debtType } = props;
  const [sideData, setSideData] = useState([]);
  const [data, setData] = useState([]);
  const [links, setLinks] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [showDetail, setShowDetail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [quesation, setQuesation] = useState("");
  const [page, setPage] = useState("1");
  const [indexx, setIndexx] = useState("");
  const itemsPerPage = 10;

  const [pageload, setPageLoad] = useState(false);



  const ApiMainFunction = async (pageNo) => {
    setLoading(true);
    // let id = couponCode ? couponCode : "";
    // let time = startDate != "" ? moment(startDate).format("YYYY-MM-DD") : "";
    let time = startDate != "" ? moment(startDate).format("MM-DD-YYYY") : "";
    let time2 = startDateFrom != "" ? moment(startDateFrom).format("MM-DD-YYYY") : "";

    let data = {
      limit: 10,
      page: pageNo,
      deviceType: debtType, //iOS,Android
      from: time,
      to: time2,
    };
    // const resp = await FeedbackGetAPI(id, time, pageNo);
    const resp = await FeedbackGetAPI(data);
    if (resp.code == 200) {
      const modifiedData = resp.data.map((item) => ({ ...item, check: false }));
      setLoading(false);
      setData(modifiedData);
      setQuesation(resp.feedbackQuestions)
      
      setTotalPages(resp.totalPages);
    } else {
      setData("");
      setLoading(false);
    }
  };

  useEffect(() => {
    ApiMainFunction(1);
    setPageLoad(true);
  }, []);

  useEffect(() => {
    if(startDate != ""){
      ApiMainFunction(1);
    }
  }, [startDate]);

  useEffect(() => {
    if(startDateFrom != ""){
      ApiMainFunction(1);
    }
  }, [startDateFrom]);

  useEffect(() => {
    if(debtType != ""){
      ApiMainFunction(1);
    }
  }, [debtType]);

 

  useEffect(() => {
    if (currentPage >= 1) {
      ApiMainFunction(currentPage);
    }
  }, [currentPage]);


  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };
  const EcommerceOrderColumns = (selectedOrders, onSelectOrder) => [
    {
      dataField: "No",
      text: "No",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            <p className="text-gray" style={{ width: "80px", whiteSpace: "break-spaces" }}>
              {/* {rowIndex + 1} */}
              {currentPage * itemsPerPage + rowIndex + 1 - itemsPerPage}
            </p>
          </>
        );
      },
    },
    {
      dataField: "user_email",
      text: "Account",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            {/* {
            <p className="text-gray">
              {row?.user_email
                ? row?.user_email
                : "+1 " + normalizeInput(row?.phone)}
            </p>
          } */}

            {<p className="text-gray">{row.user_details.email ? row.user_details.email : row.user_details.mobile_phone ? `+${row.user_details.mobile_phone}` : "User Deleted"}</p>}
          </>
        );
      },
    },

    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      formatter: (cellContent, row) => <>{<p className="text-gray">{moment(row?.createdAt).format("MM-DD-YYYY")}</p>}</>,
    },
    {
      dataField: "createdAt",
      text: "TIme",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {
            <p className="text-gray">
              {console.log(row)}
              {moment(row?.createdAt).format("h:mm a")}
            </p>
          }
        </>
      ),
    },
    {
      dataField: "App Quality",
      text: "App Quality",
      formatter: (cellContent, row) => (
        <>
          {/*  showDetail?.feedbacks[0].answer == "Bad"  */}
          {/* <p className="text-gray">{row?.feedbacks[0]?.answer == "Good" }</p> */}
          <h5> {row?.feedbacks[0]?.answer == "Good" ? <img src={good} /> : <img src={bad} />}</h5>
        </>
      ),
    },
    {
      dataField: "Checks useful",
      text: "Checks Useful",
      formatter: (cellContent, row) => (
        // <>{<p className="text-gray">{row?.feedbacks[1]?.answer}</p>}</>
        <h5> {row?.feedbacks[1]?.answer == "Good" ? <img src={good} /> : <img src={bad} />}</h5>
      ),
    },
    {
      dataField: "deviceType",
      text: "Device",
      formatter: (cellContent, row) => <>{<p className="text-gray">{row?.deviceType ? row?.deviceType : "-"}</p>}</>,
    },
    {
      dataField: "View",
      text: "View",
      formatter: (cellContent, row) => (
        <>
          <i
            title="View"
            class="bi bi-eye-fill fs-22 curser-pointer"
            onClick={() => {
              setOpen(true);
              setShowDetail(row);
            }}
          ></i>
        </>
      ),
    },
  ];

  const handleSelectOrder = (selectedRowIndex, config_id) => {
    setData((prevData) =>
      prevData.map((item, index) => ({
        ...item,
        check: index === selectedRowIndex,
      }))
    );
    setSelectedOrder(config_id);
    // props.getLinks(true);
  };

  useEffect(() => {
    // alert(isopen);
  }, [isopen]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIndexx((pageNumber - 1) * itemsPerPage + 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };
  const endPage = Math.min(startPage + 4, totalPages);


  const getFeedbackByQuestionId = (question_id) => {
    if (!showDetail?.feedbacks) return null; // Check if feedbacks exist
    // Find the feedback that matches the given question_id
    const feedback = quesation.find(feedback => feedback._id === question_id);
    // Return the feedback object or null if not found
    return feedback.question || null;
  };



  return (
    <>
      <React.Fragment>
        <Card>
          <CardBody>
            <>
              {!loading ? (
                <>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive orders-table-main-div" id="harshu">
                        <BootstrapTable
                          keyField="config_id"
                          rowEvents={{
                            onClick: (e, row, rowIndex) => {
                              // handleSelectOrder(row.config_id);
                              handleSelectOrder(rowIndex, row.config_id, e);
                            },
                          }}
                          data={data}
                          columns={EcommerceOrderColumns(selectedOrder, handleSelectOrder)}
                          responsive
                          bordered={false}
                          striped={false}
                          classes={"table align-middle table-nowrap table-no-check table-check"}
                          headerWrapperClasses={"table-light"}
                        />
                      </div>
                    </Col>
                  </Row>

                  {data && data?.length > 0 ? (
                    <div className="pagination pagination-rounded justify-content-center mt-4">
                      <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {"<<"}
                      </button>
                      <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {"<"}
                      </button>
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                        <button key={page} onClick={() => handlePageClick(page)} disabled={currentPage === page} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn btn-light  btn-xl rounded-circle m-2"}>
                          {page}
                        </button>
                      ))}
                      {totalPages > startPage + 4}
                      <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {">"}
                      </button>
                      <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                        {">>"}
                      </button>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <p> No Data Found</p>{" "}
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: checkmargin,
                  }}
                >
                  <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                </div>
              )}
            </>
          </CardBody>
        </Card>
      </React.Fragment>

      <Modal
        isOpen={isopen}
        // role="dialog"
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        backdrop="static"
        keyboard={true}
      >
        <div className="modal-content">
          <ModalHeader
            style={{ alignItems: "inherit" }}
            // toggle={() => toggle(true)}
          >
            {/* User Name 1 */}
            {showDetail && showDetail?.user_email}
          </ModalHeader>

          {/* {
            <ModalBody>
              <div className="serviceShowMedia">
                <div>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="align-items-baseline">
                        <div className="d-flex justify-content-between w-100">
                          <h5>{showDetail && showDetail?.feedbacks[0].question_id ? getFeedbackByQuestionId(showDetail?.feedbacks[0].question_id ): "N/A"}</h5>
                          <h5 className={`${showDetail && showDetail?.feedbacks[0]?.answer === "" ? "" : "me-3 p-2 bg-white rounded"}`}>
                            {showDetail && showDetail?.feedbacks[0].answer == "Bad" ? <img src={bad} className="feedresult-img-main me-1" /> : <img src={good} className="feedresult-img-main me-1" />}
                            {showDetail && showDetail?.feedbacks[0]?.answer}
                          </h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{showDetail && showDetail?.feedbacks[0]?.comment == "" ? "N/A" : showDetail && showDetail?.feedbacks[0]?.comment}</Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header className="align-items-baseline">
                        <div className="d-flex justify-content-between w-100">
                          <h5>{showDetail && showDetail?.feedbacks[1]?.question_id ? getFeedbackByQuestionId(showDetail?.feedbacks[1]?.question_id) : "N/A"}</h5>
                          <h5 className={`${showDetail && showDetail?.feedbacks[1]?.answer === "" ? "" : "me-3 p-2 bg-white rounded"}`}>
                            {showDetail && showDetail?.feedbacks[1].answer == "Bad" ? <img src={bad} className="feedresult-img-main me-1" /> : <img src={good} className="feedresult-img-main me-1" />}
                            {showDetail && showDetail?.feedbacks[1]?.answer}
                          </h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{showDetail && showDetail?.feedbacks[1]?.comment == "" ? "N/A" : showDetail && showDetail?.feedbacks[1]?.comment}</Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header className="align-items-baseline">
                        <div className="d-flex justify-content-between w-100">
                          <h5>{showDetail && showDetail?.feedbacks[2].question_id ? getFeedbackByQuestionId(showDetail?.feedbacks[2].question_id) : "N/A"}</h5>
                          {showDetail && (
                            <h5 className={`${showDetail && showDetail?.feedbacks[2]?.answer === "" ? "" : "me-3 p-2 bg-white rounded"}`}>
                              {showDetail && showDetail?.feedbacks[2].answer == "Bad" ? <img src={bad} className="feedresult-img-main me-1" /> : showDetail?.feedbacks[2]?.answer ? <img src={good} className="feedresult-img-main me-1" /> : ""}
                              {showDetail && showDetail?.feedbacks[2]?.answer == "" ? "" : showDetail?.feedbacks[2]?.answer}
                            </h5>
                          )}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{showDetail && showDetail?.feedbacks[2]?.comment == "" ? "N/A" : showDetail && showDetail?.feedbacks[2]?.comment}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </ModalBody>
          } */}

          {
            <ModalBody>
            <div className="serviceShowMedia">
              <div>
                <Accordion defaultActiveKey="0">
                  {showDetail?.feedbacks?.map((feedback, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header className="align-items-baseline">
                        <div className="d-flex justify-content-between w-100">
                          <h5>
                            {feedback?.question_id ? getFeedbackByQuestionId(feedback?.question_id) : "N/A"}
                          </h5>
                          <h5 className={`${feedback?.answer === "" ? "" : "me-3 p-2 bg-white rounded"}`}>
                            {feedback?.answer === "Bad" ? (
                              <img src={bad} className="feedresult-img-main me-1" />
                            ) : feedback?.answer ? (
                              <img src={good} className="feedresult-img-main me-1" />
                            ) : null}
                            {feedback?.answer}
                          </h5>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>{feedback?.comment === "" ? "N/A" : feedback?.comment}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </ModalBody>
          }
          <ModalFooter className="text-center justify-content-center">
            <button type="button" className="btn btn-primary" onClick={() => setOpen(false)}>
              Cancel
            </button>
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => handleClient(e)}
            >
              {Api == 1 ? "Edit" : "Add"}
              Partner
            </button> */}
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackDetail;
